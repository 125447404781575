import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import uuid4 from "uuid4";
import { Container, Row, Col, Button } from "react-bootstrap";
import BlogCard from "../components/blog-card";
import { SafeHtmlParser } from "../components/safe-html-parser";

const PageTemplate = ({ data: { site, projectTypes, allWpProject } }) => {
	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Project Type",
				item: {
					url: `${siteUrl}/project-type`,
					id: `${siteUrl}/project-type`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: projectTypes?.name,
				item: {
					url: `${siteUrl}/project-type/${projectTypes.slug}`,
					id: `${siteUrl}/project-type/${projectTypes.slug}`,
				},
			},
		],
	};

	const {
		seoFieldGroups,
		// pageFieldsGroup: { pageFieldsGroup },
	} = projectTypes;

	const projectDesc = (pageData) => {
		const { pageFieldsGroup } = pageData;

		if (pageFieldsGroup && pageFieldsGroup.length > 0) {
			const projectDetail = pageFieldsGroup.filter(
				(item) =>
					item.fieldGroupName ===
					"Project_Pagefieldsgroup_PageFieldsGroup_SectionLayout12"
			)[0];
			return projectDetail.description;
		}
	};
	// console.log(projectTypes.projectTypeFields);
	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/project-type/${projectTypes.slug}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@amberthdesign",
					site: "@amberthdesign",
					cardType: "summary",
				}}
			/>

			<Layout>
				<section className={`my-5 my-md-6 my-xl-8`}>
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} className={`text-center`}>
								<h1 className="mb-4 intro-heading">
									{projectTypes.projectTypeFields.detailPageTitle}
								</h1>
								{projectTypes.projectTypeFields.detailPageDescription && (
									<div className="px-4 pt-2">
										<SafeHtmlParser
											htmlContent={
												projectTypes.projectTypeFields.detailPageDescription
											}
										/>
									</div>
								)}
								{projectTypes.projectTypeFields.hasCtaButton && (
									<Button
										className="px-4 py-3 mt-4  mb-0 mx-auto w-fit button-fade-1 daxPro-semibold"
										href={projectTypes.projectTypeFields.ctaButton?.url}
										variant="secondary"
										target="_blank"
										rel="noreferrer"
									>
										{projectTypes.projectTypeFields.ctaButton?.title}
									</Button>
								)}
							</Col>
						</Row>
						{allWpProject && allWpProject.nodes.length > 0 && (
							<>
								<Row className="g-40 mt-4">
									{allWpProject.nodes.map((item) => (
										<BlogCard
											title={item.title}
											image={
												item.projectFields.projectFeatureImage?.localFile
													.childImageSharp.gatsbyImageData
											}
											uri={item.uri}
											excerpt={projectDesc(item.pageFieldsGroup)}
											imageAlt={item.projectFields?.projectFeatureImage.altText}
											key={uuid4()}
											textSize="fs-6"
											showArrow={true}
										/>
									))}
								</Row>
							</>
						)}
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default PageTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		projectTypes: wpProjectType(slug: { eq: $id }) {
			id
			name
			slug
			projectTypeFields {
				detailPageDescription
				detailPageTitle
				hasCtaButton
				ctaButton {
					title
					url
				}
				image {
					altText
				}
			}
			seoFieldGroups {
				metaTitle
				metaDescription
				localBusinessSchema
				openGraphDescription
				openGraphTitle
				productSchema
				image {
					altText
					publicUrl
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
		}
		allWpProject(
			filter: { terms: { nodes: { elemMatch: { slug: { eq: $id } } } } }
		) {
			nodes {
				id
				slug
				title
				uri
				pageFieldsGroup {
					pageFieldsGroup {
						... on WpProject_Pagefieldsgroup_PageFieldsGroup_SectionLayout12 {
							description
							fieldGroupName
							heading
						}
					}
				}
				projectFields {
					projectFeatureImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
	}
`;
